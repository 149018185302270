import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import {
    setItem,
    setUsers,
    setDates,
    setExport,
    setRefreshAbuseCount,
    setRefreshDetails,
    setAbuseReportCount,
} from '@/redux/slices/sharedSlice'
import {
    updateSingleField,
    addRemovedIds,
} from '@/redux/slices/featuredContentSlice'

import axios from '@/helpers/axios'

const useFetcher = () => {
    const selectedItem = useSelector((state) => state.shared.item)
    const selectedDates = useSelector((state) => state.shared.dates)
    const selectedUsers = useSelector((state) => state.shared.users)
    const featuredContent = useSelector(
        (state) => state.featuredContent.content
    )
    const exportCount = useSelector((state) => state.shared.export)
    const refreshAbuseCount = useSelector(
        (state) => state.shared.refreshAbuseCount
    )
    const refreshDetails = useSelector((state) => state.shared.refreshDetails)
    const abuseReportCount = useSelector(
        (state) => state.shared.abuseReportCount
    )

    const dispatch = useDispatch()
    const fetchItem = useCallback(
        async (url) => {
            if (url) {
                const {
                    data: { data },
                } = await axios.get(url)
                dispatch(setItem(data))
            } else {
                dispatch(setItem(null))
            }
        },
        [dispatch]
    )

    const setSelectedItem = useCallback(
        (data) => {
            dispatch(setItem(data))
        },
        [dispatch]
    )
    const setSelectedDates = useCallback(
        (data) => {
            dispatch(setDates(data))
        },
        [dispatch]
    )
    const setSelectedUsers = useCallback(
        (data) => {
            dispatch(setUsers(data))
        },
        [dispatch]
    )
    const setExportCount = useCallback(
        (data) => {
            dispatch(setExport(data))
        },
        [dispatch]
    )

    const setFeaturedContent = useCallback(
        (data) => {
            dispatch(updateSingleField(data))
        },
        [dispatch]
    )

    const setFeaturedContentRemovedIds = useCallback(
        (data) => {
            dispatch(addRemovedIds(data))
        },
        [dispatch]
    )
    const resetAbuseCount = useCallback(
        (data) => {
            dispatch(setRefreshAbuseCount(data))
        },
        [dispatch]
    )
    const setUpdateDetailFlag = useCallback(
        (data) => {
            dispatch(setRefreshDetails(data))
        },
        [dispatch]
    )
    const setActiveReportsCount = useCallback(
        (data) => {
            dispatch(setAbuseReportCount(data))
        },
        [dispatch]
    )

    return {
        fetchItem,
        exportCount,
        selectedItem,
        selectedDates,
        selectedUsers,
        setExportCount,
        resetAbuseCount,
        setSelectedItem,
        setSelectedDates,
        featuredContent,
        setSelectedUsers,
        refreshAbuseCount,
        setFeaturedContent,
        setFeaturedContentRemovedIds,
        setUpdateDetailFlag,
        refreshDetails,
        setActiveReportsCount,
        abuseReportCount,
    }
}

export default useFetcher
