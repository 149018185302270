import dayjs from '@/helpers/dayjs'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    item: null,
    users: null,
    export: 0,
    refreshAbuseCount: true,
    dates: {
        startDate: dayjs(process.env.NEXT_PUBLIC_PROJECT_START_DATE).format(
            'YYYY-MM-DD'
        ),
        endDate: dayjs().format('YYYY-MM-DD'),
        status: 'all_time',
    },
    refreshDetails: false,
    abuseReportCount: null,
}

export const sharedSlice = createSlice({
    name: 'shared',
    initialState,
    reducers: {
        setItem: (state, { payload }) => {
            state.item = payload
        },
        setUsers: (state, { payload }) => {
            state.users = payload
        },
        setExport: (state, { payload }) => {
            state.export = payload
        },
        setRefreshAbuseCount: (state, { payload }) => {
            state.refreshAbuseCount = payload
        },
        setDates: (state, { payload }) => {
            state.dates = payload
        },
        setRefreshDetails: (state, { payload }) => {
            state.refreshDetails = payload
        },
        setAbuseReportCount: (state, { payload }) => {
            state.abuseReportCount = payload
        },
    },
})

export const {
    setItem,
    setUsers,
    setExport,
    setRefreshAbuseCount,
    setDates,
    setRefreshDetails,
    setAbuseReportCount,
} = sharedSlice.actions

export default sharedSlice.reducer
