import { useRouter } from 'next/router'

import Preloader from '@/components/base/Preloader'

import { useSession } from '@/contexts/Session'

const Auth = ({ children }) => {
    const { push, asPath } = useRouter()
    const { isAuthenticated, setNextURL } = useSession({
        required: true,
        onUnauthenticated() {
            setNextURL(asPath)
            if (asPath !== '/') {
                push('/')
            }
        },
    })

    if (isAuthenticated) {
        return children
    }

    return <Preloader></Preloader>
}

export default Auth
