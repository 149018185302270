import { useEffect, useState, useCallback } from 'react'

import useFetcher from '@/redux/hooks/useFetcher'

import axios from '@/helpers/axios'

const Count = ({ uri }) => {
    const [count, setCount] = useState(0)
    const { refreshAbuseCount, resetAbuseCount, setActiveReportsCount } =
        useFetcher()

    /* get reported streams count */
    const getCount = useCallback(async () => {
        try {
            const { data } = await axios.get(uri)
            if (data?.data) {
                setCount(data.data?.total)
                setActiveReportsCount(data?.data)
            } else {
                setCount(0)
                setActiveReportsCount(null)
            }
        } catch ({ response }) {
            if (response?.data?.message) {
                setCount(0)
                setActiveReportsCount(null)
            }
        }
        resetAbuseCount(false)
    }, [resetAbuseCount, uri, setActiveReportsCount])

    useEffect(() => {
        if (refreshAbuseCount) {
            getCount()
        }
    }, [refreshAbuseCount, getCount])

    return (
        <>
            {!!count && (
                <div className="absolute right-2 top-2 bottom-0 z-10 flex h-[20px] w-[20px] items-center justify-center rounded-3xl bg-danger-700 text-xs">
                    {count >= 100 ? '99+' : count}
                </div>
            )}
        </>
    )
}
export default Count
