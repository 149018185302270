import React from 'react'
import styles from '@/styles/Error.module.css'

const SecondaryLayout = (props) => {
    return (
        <>
            <div>
                <div className={styles.errorPage}>
                    <div className={styles.inner}>
                        <div className="relative">{props.children}</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SecondaryLayout
