import { configureStore } from '@reduxjs/toolkit'

import sharedReducer from './slices/sharedSlice'
import featuredContentReducer from './slices/featuredContentSlice'

export const store = configureStore({
    reducer: {
        shared: sharedReducer,
        featuredContent: featuredContentReducer,
    },
})
