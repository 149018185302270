import SideBar from '@/components/base/SideBar'
import styles from '@/styles/Layout.module.css'

const Main = (props) => {
    return (
        <main className="relative">
            <SideBar />
            <div className={styles.rightLayout}>{props.children}</div>
        </main>
    )
}
export default Main
