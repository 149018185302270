import set from 'lodash/set'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    content: {
        recommendedChannels: {
            ids: [],
            removedIds: [],
        },
        topCarousel: {
            ids: [],
            removedIds: [],
            display: false,
        },
        topCategories: {
            ids: [],
            removedIds: [],
            display: false,
            auto: false,
        },
        topLiveStreams: {
            ids: [],
            removedIds: [],
            display: false,
            auto: false,
        },
        topStreamers: {
            ids: [],
            removedIds: [],
            display: false,
            auto: false,
        },
        topCategoryLiveStream: {
            ids: [],
            removedIds: [],
            display: false,
        },
    },
}

export const featuredContentSlice = createSlice({
    name: 'featuredContent',
    initialState,
    reducers: {
        updateSingleField(state, { payload }) {
            set(state.content[payload?.key], payload?.dataKey, payload.value)
        },

        addRemovedIds(state, { payload }) {
            const removedIds = state.content[payload?.key].removedIds
            removedIds.push(payload?.value)
            set(state.content[payload?.key], 'removedIds', removedIds)
        },
    },
})

export const { updateSingleField, addRemovedIds } = featuredContentSlice.actions

export default featuredContentSlice.reducer
