import styles from '@/styles/Auth.module.css'

const AuthLayout = (props) => {
    return (
        <main className="relative">
            <div
                className={styles.authLogin}
                style={{
                    backgroundImage: `url(/storage/auth-bg.svg)`,
                }}
            >
                <div className={styles.authContainer}>{props.children}</div>
            </div>
        </main>
    )
}
export default AuthLayout
