import { useMemo, useState, useEffect, useContext, createContext } from 'react'

import { useCookies } from 'react-cookie'

const SessionContext = createContext()

export const useSession = (options) => {
    const value = useContext(SessionContext)

    const { required, onAuthenticated, onUnauthenticated } = options ?? {}

    useEffect(() => {
        if (required === true && !value.isAuthenticated && onUnauthenticated) {
            onUnauthenticated()
        } else if (
            required === false &&
            value.isAuthenticated &&
            onAuthenticated
        ) {
            onAuthenticated()
        }
    }, [required, value.isAuthenticated, onAuthenticated, onUnauthenticated])

    return value
}

export const SessionProvider = ({ children }) => {
    const [user, setUser] = useState(null)
    const [nextURL, setNextURL] = useState()

    const [authToken, setAuthToken, removeAuthToken] = useCookies([
        'accessToken',
    ])

    const value = useMemo(
        () => ({
            user,
            setUser,
            nextURL,
            authToken,
            setNextURL,
            setAuthToken,
            removeAuthToken,
            isAuthenticated: !!authToken.accessToken,
        }),
        [user, nextURL, authToken, setAuthToken, removeAuthToken]
    )

    useEffect(() => {
        if (authToken.accessToken) {
            const getUser = async () => {
                const getUserUrl = `${process.env.NEXT_PUBLIC_API_URL}/user/me`

                const fetchOptions = {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${authToken.accessToken}`,
                    },
                }

                const response = await fetch(getUserUrl, fetchOptions)

                if (response.ok) {
                    const data = await response.json()
                    setUser(data.data)
                } else {
                    if (response.status === 401) {
                        removeAuthToken('accessToken', {
                            path: '/',
                            sameSite: true,
                        })
                    }
                }
            }
            getUser()
        } else {
            setUser(null)
        }
    }, [authToken, removeAuthToken])

    return (
        <SessionContext.Provider value={value}>
            {children}
        </SessionContext.Provider>
    )
}
